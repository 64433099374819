<template>
	<div class="ad-section" :class="emptyZoneClass + ' ' + adtype+' '+bgclass" v-if="zoneId > 0">
		<div v-if="showMpFooterCloud" class="mp-cloud-hold-footer">
			<img src="/public-static/backgrounds/mp-footer-cloud.png" />
		</div>
		<div v-if="showMagicFooterStars" class="magic-stars-hold-footer">
			<img src="/public-static/backgrounds/magic-footer-stars.png" />
		</div>
		<div class="ad-holder" :class="adholderclass" ref="holder">
			<ins :data-revive-zoneid="zoneId" data-revive-target="_blank" data-revive-id="fa2e3637a8b3a547d430a373fb4b152e" v-html="zoneContent"></ins>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: "AdSection",
	components: {},
	props: {
		adtype: String,
		backgroundclass: String,
	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
			winwidth: null,
			breakpoint: 1000,
			leaderboards: ['header','footer','midleader'],

			zoneContent: '',
			emptyZoneClass: '',
		}
	},
	computed: {
		domain() {
			return this.store.domain;
		},
		skin() {
			return this.getSkin()
		},
		showMpFooterCloud() {
			if(this.skin === 'mp' && this.adtype === 'footer') return true;
			return false;
		},
		showMagicFooterStars() {
			if(this.skin === 'magic' && this.adtype === 'footer') return true;
			return false;
		},
		bgclass() {
			if(this.backgroundclass && this.backgroundclass.length > 0) return this.backgroundclass;
			return '';
		},
		ismobile() {
			if(this.winwidth < this.breakpoint) return true;
			return false;
		},
		adholderclass() {
			if(this.leaderboards.includes(this.adtype)) {
				if(this.ismobile) return 'mobile-leaderboard';
				else return 'desktop-leaderboard';
			}
			else if(this.adtype === 'body') {
				if(this.ismobile) return 'mobile-body';
				else return 'desktop-body';
			}
			return '';
		},
		zoneId() {
			let dom = this.store.domain
			let zones = this.store.globals.domainInfo.reviveZones
			let zoneId = 0
			if(this.adtype === 'header' && this.ismobile === false) zoneId = zones.header_desktop;
			else if(this.adtype === 'header' && this.ismobile === true) zoneId = zones.header_mobile;
			else if(this.adtype === 'footer' && this.ismobile === false) zoneId = zones.footer_desktop;
			else if(this.adtype === 'footer' && this.ismobile === true) zoneId = zones.footer_mobile;
			else if(this.adtype === 'body' && this.ismobile === false) zoneId = zones.body_desktop;
			else if(this.adtype === 'body' && this.ismobile === true) zoneId = zones.body_mobile;
			else if(this.adtype === 'lowerbody' && this.ismobile === false) zoneId = zones.lowerbody_desktop;
			else if(this.adtype === 'lowerbody' && this.ismobile === true) zoneId = zones.lowerbody_mobile;
			// else if(this.adtype === 'midleader' && this.ismobile === false) zoneId = zones.midleader_desktop;
			// else if(this.adtype === 'midleader' && this.ismobile === true) zoneId = zones.midleader_mobile;
			if(this.store.vuepage.pageid === 54921 || this.store.vuepage.pageid === 59893) { //fishing show & podcast - 3mp and others
				if(this.ismobile === false) { //desktop zones
					if(this.adtype === 'header') return 141;
					else if(this.adtype === 'footer') return 143;
					else if(this.adtype === 'body') return 145;
					// else if(this.adtype === 'midleader') return 147;
				}
				else { //mobile zones
					if(this.adtype === 'header') return 142;
					else if(this.adtype === 'footer') return 144;
					else if(this.adtype === 'body') return 146;
					// else if(this.adtype === 'midleader') return 148;
				}
			}
			//custom overwrites for 3MP page specific zones
			if(dom === '3mp') {
				var eventTemplates = ['aggregate-events','article-events'];
				//desktop-overlayzoneid=139, mobile-overlayzoneid=140 if needed again
				if(eventTemplates.includes(this.store.pagetemplate)) {
					if(this.ismobile === false) { //desktop zones
						if(this.adtype === 'header') return 135;
						else if(this.adtype === 'footer') return 133;
						else if(this.adtype === 'body') return 131;
						// else if(this.adtype === 'midleader') return 137;
						else if(this.adtype === 'side-tower-ad-left') return 129;
						else if(this.adtype === 'side-tower-ad-right') return 130;
					}
					else { //mobile zones
						if(this.adtype === 'header') return 136;
						else if(this.adtype === 'footer') return 134;
						else if(this.adtype === 'body') return 132;
						// else if(this.adtype === 'midleader') return 138;
					}
				}
				else if(this.store.vuepage.pageid === 65465) { //3mp Podcast Travel Project
					if(this.ismobile === false) { //desktop zones
						if(this.adtype === 'header') return 253;
						else if(this.adtype === 'footer') return 251;
						else if(this.adtype === 'body') return 249;
					}
					else { //mobile zones
						if(this.adtype === 'header') return 254;
						else if(this.adtype === 'footer') return 252;
						else if(this.adtype === 'body') return 250;
					}
				}
			}
			//custom overwrites for 2UE page specific zones
			if(dom === '2ue') {
				if(this.store.vuepage.pageid === 82191) { //2UE 100 Year (show page)
					if(this.ismobile === false) { //desktop zones
						if(this.adtype === 'header') return 259;
						else if(this.adtype === 'footer') return 257;
						else if(this.adtype === 'body') return 255;
						else if(this.adtype === 'midleader') return 261;
					}
					else { //mobile zones
						if(this.adtype === 'header') return 260;
						else if(this.adtype === 'footer') return 258;
						else if(this.adtype === 'body') return 256;
						else if(this.adtype === 'midleader') return 262;
					}
				}
			}
			return zoneId;
		},
		vp() {
			return this.store.vuepage;
		},
		storeAdContent() {
			return this.store.adZoneContent;
		},
	},
	methods: {

	},
	watch: {
		storeAdContent() {
			let myindex = this.store.adZones.indexOf(this.zoneId)
			if(this.store.adZoneContent[myindex]) {
				this.zoneContent = this.store.adZoneContent[myindex]
				if(this.zoneContent.indexOf("bannerid=0&amp;campaignid=0") > -1) {
					this.emptyZoneClass = 'emptyzone'
				}
				else this.emptyZoneClass = ''
			}
		},
		$route() {
			this.zoneContent = '';
			if(this.zoneId != null && this.zoneId !== 0) {
				this.registerAdZone(this.zoneId);
			}
		},
	},
	mounted() {
		this.winwidth = window.innerWidth;
		if(this.zoneId != null && this.zoneId !== 0) {
			this.registerAdZone(this.zoneId);
		}

		// var e = window, a = 'inner';
		// if (!('innerWidth' in window )) {
		// 	a = 'client';
		// 	e = document.documentElement || document.body;
		// }
		// var f = { width : e[ a+'Width' ] , height : e[ a+'Height' ] };
		// this.winwidth = f.width;

	}
}
</script>

<style scoped>
.ad-section {
	display: flex;
	justify-content: center;
	align-items: center;
	padding-top: 45px;
	padding-bottom: 45px;
	position: relative;
}
/*.header {*/
/*	background-color: #212121;*/
/*}*/
/*.body {*/
/*	background-color: #EAEAEA;*/
/*}*/
/*.midleader {*/
/*	background-color: #EAEAEA;*/
/*}*/
.footer {
	background-color: #000000;
}
.mp .footer {
	background-color: #14A3AB;
}
.magic .footer {
	background-color: #111111;
}
.ad-holder {
	z-index: 1;
}
.desktop-leaderboard {
	width: 728px;
	height: 90px;
}
.mobile-leaderboard {
	width: 320px;
	height: 50px;
}
/*.mp .mobile-leaderboard,*/
/*.magic .mobile-leaderboard {*/
/*	width: 320px;*/
/*	height: 50px;*/
/*}*/
.desktop-body {
	width: 970px;
	height: 250px;
}
/*.mp .desktop-body,*/
/*.magic .desktop-body {*/
/*	width: 970px;*/
/*	height: 250px;*/
/*}*/
.mobile-body {
	width: 300px;
	height: 250px;
}
/*.mp .mobile-body,*/
/*.magic .mobile-body {*/
/*	width: 300px;*/
/*	height: 250px;*/
/*}*/
.mp-cloud-hold-footer {
	position: absolute;
	bottom: 0;
	right: 0;
}
.mp-cloud-hold-footer img {
	height: 80px;
}
.magic-stars-hold-footer {
	position: absolute;
	bottom: -30px;
	right: 100px;
}
.magic-stars-hold-footer img {
	height: 110px;
}
.ad-section.emptyzone {
	height: 0;
	visibility: hidden;
	padding: 0;
}

@media screen and (max-width: 1200px) {
	.magic-stars-hold-footer {
		display: none;
	}
}
@media screen and (max-width: 1000px) {
	.mp-cloud-hold-footer img {
		height: 40px;
	}
}

</style>