<template>
	<div class="article-tile-section" :class="addclassStr">
		<div class="container">
			<div v-if="store.loading === false" class="tile-holder">
				<TileJob v-for="(st, index) in tilejobsitems" :jobitem="st" :key="index"></TileJob>
			</div>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import TileJob from "@/components/TileJob";
export default {
	name: "JobTileSection",
	components: {
		TileJob,
	},
	props: {
		tilejobsitems: Array,
		backgroundclass: String,
		addclasses: String,
	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
		}
	},
	computed: {
		addclassStr() {
			let str = "";
			if(this.backgroundclass) {
				str += this.backgroundclass+" ";
			}
			if(this.addclasses) {
				str += this.addclasses+" ";
			}
			return str;
		}
	},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>
<style scoped>
.article-tile-section {
	padding-top: 40px;
	padding-bottom: 40px;
	display: flex;
}
.tile-holder {
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;
}
.nopadbot {
	padding-bottom: 0;
}
.nopadtop {
	padding-top: 0;
}
.extrapadbot {
	padding-bottom: 80px;
}
.extrapadtop {
	padding-top: 80px;
}

@media screen and (max-width: 1050px) {
	.tile-holder {
		justify-content: space-between;
	}
}
</style>