<template>
	<div class="hero-section" :style="bgimgoverride">
		<div v-if="page" class="container structure">
			<div class="left-half">
				<div v-if="store.loading === false" class="content-hold">
					<p class="hero-title text-white">{{page.heading}}</p>
					<p class="hero-subheading text-white" v-html="page.subheading"></p>
					<ButtonTransparentWhiteText v-if="showlinkbutton" text="View More" :url="page.url" :targpagetemplate="page.pagetemplate"></ButtonTransparentWhiteText>
				</div>
			</div>
			<div v-if="store.loading === false && page.hero_image && page.hero_image.length > 0" class="right-half">
				<div class="image-container">
					<img class="hero-image" :class="{nonEtched : page.heroImageIsEtched === false}" :src="page.hero_image" :alt="page.heading+' image'" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import ButtonTransparentWhiteText from "@/components/ButtonTransparentWhiteText";
export default {
	name: "HeroSection",
	components: {
		ButtonTransparentWhiteText
	},
	mixins: [globalMixin],
	props: {
		page: Object,
		showlinkbutton: Boolean,
	},
	data: function () {
		return {
			store: store,
		}
	},
	computed: {
		bgimgoverride() {
			if(this.page && this.page.background_override_image && this.page.background_override_image.length > 0) {
				return "background-image: url('"+this.page.background_override_image+"') !important;";
			}
			return "";
		},
	},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>
.hero-section {
	height: 500px;
	max-height: 500px;
	background-size: cover;
	background-repeat: no-repeat;
}
.hero-title {
	font-size: 65px;
	font-family: var(--secondary-font);
	font-weight: 500;
	line-height: 1.1;
	margin-bottom: 20px;
}
.hero-subheading {
	font-size: 22px;
	font-family: var(--secondary-font);
	margin-bottom: 25px;
}
.container {
	max-height: 100%;
}
.am .hero-section {
	background-image: url('/public-static/backgrounds/home-hero-bg-am.jpg');
}
.fm .hero-section {
	background-image: url('/public-static/backgrounds/home-hero-bg-fm.jpg');
}
.mp .hero-section {
	background-image: url('/public-static/backgrounds/home-hero-bg-mp.jpg');
}
.magic .hero-section {
	background-image: url('/public-static/backgrounds/home-hero-bg-magic.jpg');
}
.structure {
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;
	height: 100%;
}
.left-half {
	width: 49%;
}
.right-half {
	max-height: 100%;
	height: 100%;
	position: relative;
	width: 49%;
	overflow: hidden;
}
.image-container {
	max-height: 100%;
	height: 100%;
	position: relative;
	display: flex;
	align-items: flex-end;
	justify-content: center;
}
.hero-image {
	max-height: 100%;
	max-width: 100%;
}
.hero-image.nonEtched {
	mask: linear-gradient(to right, rgba(0,0,0,0) 0%, rgba(0,0,0,0.3) 7%, rgba(0,0,0,0.5) 10%, rgba(0,0,0,1) 20%, rgba(0,0,0,1) 80%, rgba(0,0,0,0.5) 90%, rgba(0,0,0,0.3) 93%, rgba(0,0,0,0) 100%);
	height: 100%;
	width: 100%;
	object-fit: cover;
}

@media screen and (max-width: 1630px) {
	.left-half {
		width: 40%;
	}
	.right-half {
		width: 59%;
	}
	.hero-title {
		font-size: 50px;
	}
}
@media screen and (max-width: 1220px) {
	.hero-title {
		font-size: 40px;
	}
	.hero-subheading {
		font-size: 18px;
	}
}
@media screen and (max-width: 959px) {
	.am .hero-section {
		background-image: url('/public-static/backgrounds/home-hero-bg-mob-am.jpg');
	}
	.fm .hero-section {
		background-image: url('/public-static/backgrounds/home-hero-bg-mob-fm.jpg');
	}
	.mp .hero-section {
		background-image: url('/public-static/backgrounds/home-hero-bg-mob-mp.jpg');
	}
	.magic .hero-section {
		background-image: url('/public-static/backgrounds/home-hero-bg-mob-magic.jpg');
	}
	.container {
		width: 100%;
		max-width: 100%;
		padding: 0;
	}
	.structure {
		display: block;
		position: relative;
	}
	.hero-section {
		height: 500px;
		max-height: 500px;
	}
	.hero-image.nonEtched {
		mask: unset;
	}
	.left-half {
		width: 100%;
		position: absolute;
		z-index: 1;
		text-align: center;
		display: flex;
		height: 100%;
		align-items: flex-end;
		padding-bottom: 30px;
		background-image: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 40%, rgba(0,0,0,1) 65%, rgba(0,0,0,1) 100%);
	}
	.fm .left-half {
		background-image: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 40%, rgba(43,2,51,1) 65%, rgba(43,2,51,1) 100%);
	}
	.am .left-half {
		background-image: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 40%, rgba(0,45,88,1) 65%, rgba(0,45,88,1) 100%);
	}
	.mp .left-half {
		background-image: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 40%, rgba(37,190,200,1) 65%, rgba(37,190,200,1) 100%);
	}
	.magic .left-half {
		background-image: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 40%, rgba(240,99,18,1) 65%, rgba(240,99,18,1) 100%);
	}
	.left-half .content-hold {
		width: 90%;
		margin: 0 auto;
	}
	.skeleton-hold {
		text-align: center;
	}
	.skeleton-hold > div {
		margin-left: auto;
		margin-right: auto;
	}
	.right-half {
		width: 100%;
		height: 65%;
	}
}
@media screen and (max-width: 900px) {
	.hero-section {
		position: relative;
	}
}
</style>