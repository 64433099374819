<template>
	<div class="tile-podcast">
		<a v-if="store.loading === false && showitem" :href="showitem.url" class="tile-linker" :data-targpagetpl="showitem.pagetemplate">
			<div v-if="showitem.hero_image && showitem.hero_image.length > 0" class="image-parent">
				<div class="image-container">
					<img class="hero-image nonEtched" :src="showitem.heroImageNonEtched" :alt="showitem.heading+' image'" />
				</div>
			</div>
			<div class="title-holder">
				<p class="show-title">{{showitem.heading}}</p>
				<p class="show-subtitle">{{showitem.subheading}}</p>
			</div>
		</a>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: "TilePodcast",
	components: {},
	props: {
		showitem: Object,
	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
		}
	},
	computed: {},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>
.tile-podcast {
	/*box-shadow: 0 0 10px 7px rgb(0 0 0 / 8%);*/
	max-width: 380px;
	width: 24%;
	height: 160px;
	transition: all 0.3s ease-in-out;
}
/*
.tile-podcast:hover {
	box-shadow: 0 0 10px 7px rgb(0 0 0 / 14%);
}*/
.tile-linker {
	display: flex;
}
.image-parent {
	width: 160px;
	height: 160px;
	overflow: hidden;
	flex-shrink: 0;
	border-radius: 10px;
}
.image-container {
	width: 100%;
	height: 100%;
}
.hero-image {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.title-holder {
	padding: 20px;
}
.show-title {
	font-weight: bold;
	font-size: 13px;
	margin-bottom: 10px;
}
.show-subtitle {
	font-size: 12px;
}
@media screen and (max-width: 1400px) {
	.tile-podcast {
		max-width: unset;
		width: 48%;
		margin-bottom: 20px;
	}
}
@media screen and (max-width: 960px) {
	.tile-podcast {
		width: 100%;
	}
}
</style>