<template>
	<div class="hero-section">
		<div class="hero-section-left-behind"></div>
		<div v-if="page" class="container structure">
			<div class="left-half">
				<div v-if="store.loading === false" class="content-hold">
					<a v-if="fullLeftImage" :href="page.url" :data-targpagetpl="targpagetemplate" class="full-image-a">
						<img v-if="heroImageUrl" class="hero-image" :src="heroImageUrl" :alt="page.heading+' image'" />
					</a>
					<a v-else class="news-article-left-section" :href="page.url" :data-targpagetpl="targpagetemplate">
						<div class="news-article-text-section">
							<p class="hero-title">{{page.heading}}</p>
							<p class="hero-subheading" v-html="page.article_excerpt"></p>
						</div>
						<div class="news-article-image-section">
							<img v-if="heroImageUrl" class="hero-image" :src="heroImageUrl" :alt="page.heading+' image'" />
						</div>
					</a>
				</div>
			</div>
			<div v-if="store.loading === false" class="right-half">
				<div class="news-feed">
					<p class="latest-news-title">Latest News</p>
					<div class="latest-news-tiles">
						<a v-for="(lat, ind) in latestNewsArticles" :key="ind" :href="lat.url" data-targpagetpl="article-news" class="latest-tile">
							<i class="mdi mdi-circle"></i>
							<p class="latest-title">{{lat.heading}}</p>
							<div class="latest-excerpt" v-html="lat.article_excerpt"></div>
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"

export default {
	name: "HeroSectionHome25",
	components: {

	},
	mixins: [globalMixin],
	props: {
		page: Object,
	},
	data: function () {
		return {
			store: store,
		}
	},
	computed: {
		targpagetemplate() {
			if(this.page && this.page.pagetemplate) return this.page.pagetemplate
			return null
		},
		fullLeftImage() {
			let showFullLeftImageTypes = ['article-competition', 'article-shows']
			return showFullLeftImageTypes.includes(this.targpagetemplate)
		},
		heroImageUrl() {
			if(this.page && this.page.heroImageNonEtched) return this.page.heroImageNonEtched
			return null
		},
		latestNewsArticles() {
			if(this.store.vuepage.slotprops.newslatest && this.store.vuepage.slotprops.newslatest.length > 0) {
				return this.store.vuepage.slotprops.newslatest
			}
			return null
		},
	},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>
.hero-section {
	background-size: cover;
	background-repeat: no-repeat;
	background-color: #FAFAFA;
}
.hero-section-left-behind {
	background-color: #FFFFFF;
	width: 50%;
	height: 600px;
	position: absolute;
}
.hero-title {
	font-size: 45px;
	font-family: var(--secondary-font);
	font-weight: bold;
	line-height: 1.1;
	margin-bottom: 20px;
}
.hero-subheading {
	font-size: 17px;
	font-family: var(--secondary-font);
	margin-bottom: 25px;
}
.container {
	max-height: 100%;
}
.structure {
	display: flex;
	justify-content: space-between;
	align-items: stretch;
	position: relative;
	height: 100%;
}
.left-half {
	width: 74%;
	padding: 50px 4% 50px 0;
	background-color: #FFF;
}
.right-half {
	height: 600px;
	position: relative;
	width: 26%;
	overflow: hidden;
	background: #FAFAFA;
}
.news-feed {
	height: 100%;
	padding: 70px 20px 70px 40px;
	width: 100%;
	background: #FAFAFA;
	overflow: hidden;
}
a.full-image-a {
	display: block;
	height: 500px;
	background: #FAFAFA;
	border-radius: 20px;
}
a.news-article-left-section {
	display: flex;
	height: 500px;
}
.news-article-text-section {
	width: 50%;
	padding: 30px 50px 30px 0;
	height: 100%;
}
.news-article-image-section {
	width: 50%;
	height: 100%;
	background-color: #FAFAFA;
	border-radius: 20px;
}
a.full-image-a img {
	height: 100%;
	width: 100%;
	object-fit: cover;
	border-radius: 20px;
}
.hero-image {
	max-height: 100%;
	max-width: 100%;
	height: 100%;
	width: 100%;
	object-fit: cover;
	border-radius: 20px;
}
.latest-news-title {
	font-size: 14px;
	text-transform: uppercase;
	font-weight: bold;
	margin-bottom: 30px;
}
.latest-tile {
	border-left: 1px solid #D1D1D1;
	padding: 0 0 40px 20px;
	position: relative;
	display: block;
}
.latest-tile:last-of-type {
	padding-bottom: 0;
}
.latest-title {
	font-size: 16px;
	font-weight: bold;
	margin-bottom: 5px;
	top: -6px;
	position: relative;
}
.latest-excerpt {
	font-size: 14px;
}
.mdi-circle {
	color: #D1D1D1;
	top: -5px;
	left: -7px;
	position: absolute;
	font-size: 14px;
}

@media screen and (max-width: 1399px) {
	.hero-title {
		font-size: 35px;
	}
}
@media screen and (max-width: 1099px) {
	.left-half {
		width: 60%;
	}
	.right-half {
		width: 36%;
	}
	.structure {
		flex-wrap: wrap;
		flex-direction: column;
	}
	.container, .structure {
		width: 100%;
		max-width: 100%;
		padding-left: 0;
		padding-right: 0;
	}
	.left-half {
		width: 90%;
		padding: 50px 0;
		margin: 0 auto;
	}
	.right-half {
		width: 100%;
		height: auto;
		background-color: #FFF;
	}
	.news-feed {
		width: 90%;
		margin: 0 auto;
		padding: 20px 0 60px 10px;
		background-color: #FFF;
	}
	.latest-news-tiles {
		display: flex;
		gap: 70px;
	}
	.latest-tile {
		width: 50%;
		padding-bottom: 0;
	}
	.hero-section {
		background-color: #FFF;
	}
	.hero-section-left-behind {
		display: none;
	}
}
@media screen and (max-width: 899px) {
	a.news-article-left-section {
		height: 400px;
	}
	a.full-image-a {
		height: 400px;
	}
}
@media screen and (max-width: 699px) {
	.left-half {
		padding-bottom: 0;
	}
	a.full-image-a {
		height: 300px;
	}
	a.news-article-left-section {
		flex-direction: column;
		height: auto;
	}
	.news-article-text-section {
		width: 100%;
		order: 2;
		padding: 30px 0;
	}
	.news-article-image-section {
		width: 100%;
		height: 300px;
		order: 1;
	}
	.hero-title {
		font-size: 26px;
	}
	.latest-news-tiles {
		flex-wrap: wrap;
		gap: 0;
	}
	.latest-tile {
		width: 100%;
		padding-bottom: 40px;
	}
}
</style>