<template>
	<div>
		<HeroSection :page="store.vuepage.slotprops.heropage" :showlinkbutton="true"></HeroSection>

		<AdSection adtype="header" backgroundclass="bglightgrey"></AdSection>

		<ArticleTileSection :tilenewsitems="news1" addclasses="nopadtop nopadbot"></ArticleTileSection>

		<AdSection adtype="body" backgroundclass=""></AdSection>

		<ArticleTileSection :tilenewsitems="news2"></ArticleTileSection>

		<AdSection adtype="midleader" backgroundclass=""></AdSection>

		<ArticleTileSection :tilenewsitems="news3"></ArticleTileSection>

		<ShowTileSection :tileshows="store.vuepage.slotprops.showtiles" backgroundclass="bgblack"></ShowTileSection>

		<FooterSection></FooterSection>
	</div>
</template>

<script>
import AdSection from "@/components/AdSection";
import ShowTileSection from "@/components/ShowTileSection";
import ArticleTileSection from "@/components/ArticleTileSection";
import FooterSection from "@/components/FooterSection";
import HeroSection from "@/components/HeroSection";
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: "AggregateComps",
	components: {
		HeroSection,
		FooterSection,
		ArticleTileSection,
		ShowTileSection,
		AdSection,
	},
	mixins: [globalMixin],
	props: {

	},
	data: function () {
		return {
			store: store,
		}
	},
	computed: {
		vp() {
			return this.store.vuepage;
		},
		news1() {
			var ret = [];
			if(this.vp && this.store.loading === false) {
				for(var i=0; i<4; i++) {
					if(this.vp.slotprops.newstiles && this.vp.slotprops.newstiles[i]) {
						ret.push(this.vp.slotprops.newstiles[i]);
					}
				}
			}
			return ret;
		},
		news2() {
			var ret = [];
			if(this.vp && this.store.loading === false) {
				for(var i=4; i<12; i++) {
					if(this.vp.slotprops.newstiles && this.vp.slotprops.newstiles[i]) {
						ret.push(this.vp.slotprops.newstiles[i]);
					}
				}
			}
			return ret;
		},
		news3() {
			var ret = [];
			if(this.vp && this.store.loading === false) {
				for(var i=12; i<20; i++) {
					if(this.vp.slotprops.newstiles && this.vp.slotprops.newstiles[i]) {
						ret.push(this.vp.slotprops.newstiles[i]);
					}
				}
			}
			return ret;
		},
	},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>

</style>