<template>
	<div v-if="nowplaying.artist && recents.length > 0" class="recently-played-section">
		<div class="container recent-contain">
			<div class="main-item-holder">
				<div>
					<p class="minor-heading">Latest</p>
				</div>
				<div class="main-item">
					<div class="main-image">
						<img :src="nowplaying.imageurl" :alt="nowplaying.artist+' - '+nowplaying.title" />
					</div>
					<div class="main-item-content">
						<div>
							<p class="main-artist">{{nowplaying.artist}}</p>
							<p class="main-title">{{nowplaying.title}}</p>
						</div>
					</div>
				</div>
			</div>
			<div class="secondary-items">
				<div>
					<p class="recent-text text-accent">Recent</p>
				</div>
				<div class="secondary-items-group">
					<div v-for="(npitem, index) in recents" :key="index" class="secondary-item">
						<div class="secondary-image">
							<img :src="npitem.imageurl" :alt="npitem.artist+' - '+npitem.title" />
						</div>
						<div class="secondary-item-content">
							<div>
								<p class="secondary-artist">{{npitem.artist}}</p>
								<p class="secondary-title">{{npitem.title}}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import axios from "axios";

export default {
	name: "RecentlyPlayedSection",
	components: {},
	props: {},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
			fetching: false,
			nowplaying: {},
			recents: [],
		}
	},
	computed: {
		dom() {
			return this.store.domain;
		},
		xmlurl() {
			// let u = 'https://phpstack-280766-2391852.cloudwaysapps.com/?stn=';
			let u = 'https://nowplaying.aceradio.com.au/?stn=';
			let f = "";
			if(this.dom === 'magic1278') f = 'magic1278';
			else if(this.dom === '3mp') f = '3mp';
			else if(this.dom === '2ue') f = '2ue';
			else if(this.dom === '4bh') f = '4bh';
			if(f.length > 0) return u + f;
			return false;
		},
		defaultAlbumArtUrl() {
			return this.store.urlroot+'/public-static/album-art-placehold.png';
		},
	},
	methods: {
		getXmlFile() {
			if(this.xmlurl && this.fetching === false) {
				this.fetching = true;
				let self = this;
				axios.get(self.xmlurl).then(function (response) {
					let myitems = [];
					var ret = response.data; //response.data is returned info
					//console.log(ret);
					let parser = new DOMParser();
					let xd = parser.parseFromString(ret, 'text/xml');
					let pd = xd.getElementsByTagName('onAir')[0].getElementsByTagName('playoutData')[0];
					for(let i=0; i<4; i++) {
						let s1 = pd.getElementsByTagName('playoutItem')[i];
						let x = s1.getElementsByTagName('imageLink')[0];
						let imgurl = self.defaultAlbumArtUrl;
						if(x) imgurl = x.textContent;
						let myob = {
							artist: s1.getAttribute('artist'),
							title: s1.getAttribute('title'),
							imageurl: imgurl,
						};
						if(i === 0) self.nowplaying = myob;
						else myitems.push(myob);
					}
					self.recents = myitems;
					self.fetching = false;
				});
			}
		},
	},
	watch: {},
	mounted() {
		this.getXmlFile();
		//check for new now playing info every 45sec
		window.recplaytimer = window.setInterval(function() {
			this.getXmlFile()
		}.bind(this), 45000);
	},
	beforeUnmount() {
		window.clearInterval(window.recplaytimer);
	},
}
</script>

<style scoped>
.recently-played-section {
	background-color: #212121;
	padding-top: 30px;
	padding-bottom: 50px;
	display: flex;
}
.recent-contain {
	display: flex;
	justify-content: center;
}
.minor-heading {
	color: #FFF;
	padding: 20px 0;
}
.main-item-holder {
	width: 40%;
	min-width: 550px;
}
.main-item {
	display: flex;
}
.main-image {
	width: 200px;
	height: 200px;
	background-color: #444;
	flex-shrink: 0;
}
.main-image img {
	object-fit: cover;
	width: 100%;
	height: 100%;
}
.main-item-content {
	align-items: center;
	display: flex;
	padding: 0 0 0 20px;
}
.main-artist {
	color: #FFF;
	font-size: 35px;
	font-family: var(--secondary-font);
	font-weight: 500;
	margin-bottom: 6px;
}
.main-title {
	color: #FFF;
	font-family: var(--secondary-font);
	font-size: 22px;
}
.secondary-items {
	margin-left: 50px;
}
.recent-text {
	font-size: 14px;
	padding: 26px 0 26px 20px;
	font-weight: 500;
	font-family: var(--secondary-font);
	text-transform: uppercase;
}
.secondary-items-group {
	display: flex;
	justify-content: space-between;
}
.secondary-item {
	width: 180px;
	text-align: center;
}
.secondary-image {
	width: 140px;
	height: 140px;
	background-color: #444;
	margin: 0 auto;
	flex-shrink: 0;
}
.secondary-image img {
	object-fit: cover;
	width: 100%;
	height: 100%;
}
.secondary-item-content {
	padding: 10px 10px;
}
.secondary-artist {
	color: #ACACAC;
	font-family: var(--secondary-font);
	font-size: 14px;
	font-weight: 500;
	margin-bottom: 4px;
}
.secondary-title {
	color: #ACACAC;
	font-family: var(--secondary-font);
	font-size: 14px;
}

@media screen and (max-width: 1200px) {
	.main-item-holder {
		min-width: 450px;
	}
	.main-artist {
		font-size: 22px;
	}
	.main-title {
		font-size: 16px;
	}
}
@media screen and (max-width: 1100px) {
	.recent-contain {
		display: block;
	}
	.main-item-holder {
		width: 100%;
	}
	.secondary-items {
		margin-left: 0;
	}
	.recent-text {
		padding: 26px 0 26px 0;
	}
}
@media screen and (max-width: 640px) {
	.main-item-holder {
		min-width: auto;
	}
	.main-image {
		width: 140px;
		height: 140px;
	}
	.main-artist {
		font-size: 20px;
	}
	.main-title {
		font-size: 14px;
	}
	.secondary-items-group {
		display: block;
	}
	.secondary-item {
		width: 100%;
		display: flex;
		justify-content: flex-start;
		margin-bottom: 20px;
	}
	.secondary-image {
		margin: 0 30px 0 0;
	}
	.secondary-item-content {
		text-align: left;
		display: flex;
		align-items: center;
	}
}
</style>