<template>
	<div class="text-white">
		<a :href="url" class="button-transparent-white-text" :data-targpagetpl="targpagetemplate">{{text}}</a>
	</div>
</template>

<script>
export default {
	name: "ButtonTransparentWhiteText",
	components: {},
	props: {
		target: String,
		url: String,
		text: String,
		targpagetemplate: String,
	},
	data: function () {
		return {
			// isInternalLink: true,
		}
	},
	computed: {

	},
	methods: {},
	watch: {},
	mounted() {
		// if(this.url.indexOf("/") === 0) { //this is an internal link
		// 	this.isInternalLink = true;
		// }
	}
}
</script>
<style scoped>
.button-transparent-white-text {
	display: inline-block;
	color: #FFF;
	font-size: 14px;
	font-family: var(--secondary-font);
	text-transform: uppercase;
	border: 1px solid #FFF;
	transition: all 0.3s ease-in-out;
	font-weight: 700;
	padding: 12px 36px;
	border-radius: 30px;
	letter-spacing: 0.05em;
}
.button-transparent-white-text:hover {
	color: #000;
	background-color: #FFF;
	text-transform: uppercase;
}
</style>